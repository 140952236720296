.ant-upload-list-item-list-type-text {
  width: 200px;
}
.leaflet-control-geosearch form {
  display: block;
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  margin: 0 auto;
}

.leaflet-control-geosearch form input {
  width: 100%;
}

.leaflet-control-geosearch .leaflet-bar-part {
  display: none;
}

.leaflet-control-geosearch form {
  position: initial;
  right: 0;
  margin: 0 auto;
}

.leaflet-right {
  display: none;
}
